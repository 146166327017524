import axios from 'src/app-axios';
import Constants from 'src/constants';
import { backendUrl } from 'src/app-globals';

export const qrcodebanners = {
    namespaced: true,
    state: {
        bannerQrCodeDetails: [], // for creation
        qrCodeBanners: [],
        qrCodeScannedUsers: [],
        qrCodeScannedUsersCount: {},
        qrCodeBannerId: null,
    },
    getters: {
        getBannerQrCodeDetails (state) {
            return state.bannerQrCodeDetails;
        },
        getQrCodeBanners (state) {
            return state.qrCodeBanners;
        },
        getQrCodeScannedUsers (state) {
            return state.qrCodeScannedUsers;
        },
        getQrCodeScannedUsersCount (state) {
            return state.qrCodeScannedUsersCount;
        },
        getQrCodeBannerId (state) {
            return state.qrCodeBannerId;
        },
    },
    mutations: {
        setBannerQrCodeDetails (state, details) {
            const bannerIndex = state.bannerQrCodeDetails.findIndex(item => item.bannerContentId === details.bannerContentId);
            if (bannerIndex === -1) {
                state.bannerQrCodeDetails.push(details);
            } else {
                state.bannerQrCodeDetails[bannerIndex] = details;
            }
        },
        removeAttachedQrCodeDetails (state, id) {
            const filteredQrCodeBanners = state.bannerQrCodeDetails.filter(item => item.bannerContentId !== id);
            state.bannerQrCodeDetails = filteredQrCodeBanners;
        },
        setQrCodeBanners (state, banners) {
            state.qrCodeBanners = banners;
        },
        setQrCodeScannedUsers (state, users) {
            state.qrCodeScannedUsers = users;
        },
        setQrCodeScannedUsersCount (state, count) {
            state.qrCodeScannedUsersCount = count;
        },
        setQrCodeBannerId (state, bannerId) {
            state.qrCodeBannerId = bannerId;
        },
        removeQrCodeBannerId (state) {
            state.qrCodeBannerId = null;
        },
        removeAllBannerQrCodeDetails (state) {
            state.bannerQrCodeDetails = [];
        },
    },
    actions: {
        addBannerQrCodeDetails (context, payload) {
            context.commit('setBannerQrCodeDetails', payload.details);
        },
        removeBannerQrCodeDetails (context, id) {
            context.commit('removeAttachedQrCodeDetails', id);
        },
        async attachQrCodeWithBanner ({ dispatch }, payload) {
            const url = `api/qr-code/banners/${payload.bannerId}`;
            try {
                await axios.post(url, payload.qrCodeDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in attaching the QR code.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchQrCodeBanners ({ dispatch, commit }) {
            const url = 'api/qr-code/banners';
            try {
                const banners = await axios.get(url);
                commit('setQrCodeBanners', banners.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting qr code banners list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchQrCodeScannedUsers ({ dispatch, commit }, payload) {
            const url = `api/qr-code/banners/${payload.bannerId}/users`;
            try {
                const users = await axios.get(url);
                commit('setQrCodeScannedUsers', users.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting qr code scanned users list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchQrCodeScannedUsersCount ({ dispatch, commit }, payload) {
            const url = `api/qr-code/banners/${payload.bannerId}/users/count`;
            try {
                const count = await axios.get(url);
                commit('setQrCodeScannedUsersCount', count.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting qr code scanned users list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async storeQrScannedUsersCount ({ dispatch }, payload) {
            const url = `${backendUrl}/api/qr-code/banners/${payload.bannerId}/users/count`;
            try {
                await axios.post(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong, please try again later.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        storeQrCodeBannerId (context, payload) {
            context.commit('setQrCodeBannerId', payload.bannerId);
        },
        async submitQrScannedUserDetails ({ dispatch }, payload) {
            const url = `${backendUrl}/api/qr-code/banners/${payload.bannerId}/users`;
            try {
                await axios.post(url, payload.userDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong, please try again later.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        clearQrCodeBannerId (context) {
            context.commit('removeQrCodeBannerId');
        },
        clearAllBannerQrCodeDetails (context) {
            context.commit('removeAllBannerQrCodeDetails');
        },
    },
};
