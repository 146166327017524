import axios from 'src/app-axios';
import Constants from 'src/constants';

export const campaigns = {
    namespaced: true,
    state: {
        campaignFiles: [],
        campaignName: '',
        bannerFiles: [],
        userCampaignList: [],
        globalCampaignList: [],
        campaignContent: [],
        weeklyCampaignList: [],
        newlyCreatedBannerDetails: {},
    },
    getters: {
        getCampaignFiles (state) {
            return state.campaignFiles;
        },
        getCampaignName (state) {
            return state.campaignName;
        },
        getBannerFiles (state) {
            return state.bannerFiles;
        },
        getUserCampaignList (state) {
            return state.userCampaignList;
        },
        getGlobalCampaignList (state) {
            return state.globalCampaignList;
        },
        getCampaignContent (state) {
            return state.campaignContent;
        },
        getWeeklyCampaignList (state) {
            return state.weeklyCampaignList;
        },
        getNewlyCreatedBannerDetails (state) {
            return state.newlyCreatedBannerDetails;
        },
    },
    mutations: {
        setCampaignFiles (state, data) {
            state.campaignFiles.push(data);
        },
        setCampaignName (state, name) {
            state.campaignName = name;
        },
        clearCampaignFiles (state) {
            state.campaignFiles = [];
        },
        clearCampaignNames (state) {
            state.campaignName = '';
        },
        setBannerFiles (state, data) {
            state.bannerFiles.push(data);
        },
        clearBannerFiles (state) {
            state.bannerFiles = [];
        },
        removeCampaignFile (state, payload) {
            state.campaignFiles.splice(payload, 1);
        },
        setUserCampaignList (state, data) {
            state.userCampaignList = data;
        },
        setGlobalCampaignList (state, data) {
            state.globalCampaignList = data;
        },
        setSelectedCampaignFiles (state, data) {
            state.campaignFiles.push(data);
        },
        updateCampaignFiles (state, data) {
            state.campaignFiles = data;
        },
        setCampaignContent (state, data) {
            state.campaignContent = data;
        },
        clearOffCampaignContent (state) {
            state.campaignContent = [];
        },
        setWeeklyCampaignList (state, data) {
            state.weeklyCampaignList = data;
        },
        setNewlyCreatedBannerDetails (state, data) {
            state.newlyCreatedBannerDetails = data;
        },
    },
    actions: {
        async uploadCampaignFiles ({ dispatch, commit }, { formData, rotationInterval }) {
            const url = 'api/banners/content/upload';
            try {
                const response = await axios.post(url, formData);
                commit('setCampaignFiles', { rotation_interval: rotationInterval, formData: response.data });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in Uploading the campaign file.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        createCampaignName (context, name) {
            context.commit('setCampaignName', name);
        },
        clearCampaignFile ({ commit }) {
            commit('clearCampaignFiles');
        },
        clearCampaignName ({ commit }) {
            commit('clearCampaignNames');
        },
        async createBannerFiles ({ dispatch, commit }, payload) {
            const url = 'api/banners';
            try {
                const response = await axios.post(url, payload);
                commit('setBannerFiles', response.data);
                commit('setNewlyCreatedBannerDetails', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        clearBannerFile ({ commit }) {
            commit('clearBannerFiles');
        },
        async createCampaign ({ dispatch, commit }, payload) {
            const url = 'api/campaign';
            try {
              await axios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating your campaign.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        deleteCampaignFile ({ dispatch, commit }, payload) {
            try {
                commit('removeCampaignFile', payload.index);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting the campaign file.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                 throw exception;
            }
        },
        async fetchUserCampaignList ({ dispatch, commit }) {
            const url = 'api/campaign/user';
            try {
                const details = await axios.get(url);
                commit('setUserCampaignList', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your user campaign list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchGlobalCampaignList ({ dispatch, commit }) {
            const url = 'api/campaign/global';
            try {
                const details = await axios.get(url);
                commit('setGlobalCampaignList', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your global campaign list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async removeBannerFromCampaign ({ dispatch }, payload) {
            const url = `api/campaign/${payload.campaignId}/banner/${payload.bannerId}`;
            try {
                await axios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in removing banner from campaign.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async deleteCampaign ({ dispatch }, campaignId) {
            const url = `api/campaign/${campaignId}`;
            try {
                await axios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in removing the campaign.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        onSelectedContents (context, payload) {
            context.commit('setSelectedCampaignFiles', payload);
        },
        deleteSelectedFile (context, payload) {
            context.commit('removeCampaignFile', payload);
        },
        async linkCampaignToBoard ({ dispatch }, payload) {
            const url = 'api/banners/boards';
            try {
                await axios.post(url, payload);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in linking your campaign to digi-board.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        onUpdateCampaignFiles (context, payload) {
            context.commit('updateCampaignFiles', payload);
        },
        async fetchCampaignContent ({ dispatch, commit }, payload) {
            const url = `api/campaign/${payload.id}`;
            try {
                const details = await axios.get(url);
                commit('setCampaignContent', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your campaign details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        clearCampaignContent ({ commit }) {
            commit('clearOffCampaignContent');
        },
        async updateCampaign ({ dispatch }, payload) {
            const url = `api/campaign/${payload.campaignId}`;
            try {
                await axios.put(url, {
                    name: payload.name,
                    comment: payload.comment,
                    global: payload.global,
                    start: payload.start,
                    end: payload.end,
                    banners: payload.banners,
                    schedules: payload.schedules,
                });
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating your campaign link with digi-boards.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateCampaignToBoardLink ({ dispatch }, payload) {
            const url = `api/banners/boards/${payload.banner_id}`;
            try {
                await axios.post(url, payload.digi_boards);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating your campaign with digi-boards.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchWeeklyCampaignList ({ dispatch, commit }, { params }) {
            const url = 'api/campaign/schedules';
            try {
                const response = await axios.get(url, { params: params });
                commit('setWeeklyCampaignList', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting your weekly campaign list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
};
