export default Object.freeze({
    ALERT_TYPE_ERROR: 'error',
    ALERT_TYPE_SUCCESS: 'success',
    ALERT_TYPE_INFO: 'info',
    MAX_NO_OF_QUESTIONS: 5,
    MODULE_BANNER: 'banner',
    MODULE_DASHBOARD: 'dashboard',
    MODULE_REPORT: 'report',
    MODULE_SWIFT_CHECK_IN: 'swiftCheckIn',
    MODULE_SUPPORT: 'support',
    MODULE_USER_PROFILE: 'userProfile',
    MODULE_CAMPAIGN: 'campaign',
    MODULE_DIGIBOARD: 'digiBoard',
    SOCKET_ORGANIZATION_CHANNEL: 'private-organization-channel',
    SOCKET_BOARD_PUBLISH_SUCCESS: 'digiBoard.publish.success',
    SOCKET_BOARD_REGISTRATION_SUCCESS: 'digiBoard.registration.successful',
    SUB_MODULE_ADVANCED_SCHEDULING: 'advancedScheduling',
    SUB_MODULE_CURRENT_REPORTING: 'currentReporting',
    SUB_MODULE_HISTORY_REPORTING: 'historyReporting',
    USER_UPDATE_REGISTER: 'register',
    USER_PASSWORD_RESET: 'passwordReset',
    MODULE_MEDIA_LIBRARY: 'mediaLibrary',
    SUB_MODULE_MEDIA_FOLDER: 'folder',
    SUB_MODULE_TEMPLATE_EDITOR: 'templateEditor',
    SUB_MODULE_IMAGE_EDITOR: 'imageEditor',
    SOCKET_TEMPLATE_EDITOR_CONTENT_DOWNLOAD_SUCCESS: 'templateEditor.content.downloaded',

    CHECKOUT_SESSION_FAILED: 'fail',
    CHECKOUT_SESSION_SUCCESS: 'success',
    SOCKET_BOARDS_LICENCE_CREATE_SUCCESS: 'digiBoards.licences.created',
    MODULE_WIDGETS: 'widgets',
    MODULE_SOCIAL_MEDIA: 'socialMedia',
    SUB_MODULE_TICKER: 'ticker',
    SOCKET_FREE_TRIAL_LICENCE_EXPIRED: 'trial.expired',
    SOCKET_FREE_TRIAL_LICENCE_ENDS_AT: 'trial.period.end',
    SOCKET_ORGANIZATION_MODULE_UPDATED: 'module.updated',
});
