import axios from 'src/app-axios';
import Constants from 'src/constants';

export const socialmedia = {
    namespaced: true,
    state: {
        youtubeVideos: [],
        youtubeVideoForAdding: {},
        addedYoutubeVideo: {},
        youtubeVideoDetails: {},
        editedYoutubeVideoDetails: {},
    },
    getters: {
        getYoutubeVideos (state) {
            return state.youtubeVideos;
        },
        getYoutubeVideoForAdding (state) {
            return state.youtubeVideoForAdding;
        },
        getAddedYoutubeVideo (state) {
            return state.addedYoutubeVideo;
        },
        getYoutubeVideoDetails (state) {
            return state.youtubeVideoDetails;
        },
        getEditedYoutubeVideoDetails (state) {
            return state.editedYoutubeVideoDetails;
        },
    },
    mutations: {
        setYoutubeVideos (state, data) {
            state.youtubeVideos = data;
        },
        setYoutubeVideoForAdding (state, details) {
            state.youtubeVideoForAdding = details;
        },
        setAddedYoutubeVideo (state, video) {
            state.addedYoutubeVideo = video;
        },
        removeYoutubeVideoForAdding (state) {
            state.youtubeVideoForAdding = {};
        },
        setYoutubeVideoDetails (state, details) {
            state.youtubeVideoDetails = details;
        },
        setEditedYoutubeVideoDetails (state, details) {
            state.editedYoutubeVideoDetails = details;
        },
    },
    actions: {
        async fetchYoutubeVideos ({ dispatch, commit }) {
            const url = 'api/youtube-contents';
            try {
                const response = await axios.get(url);
                commit('setYoutubeVideos', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the youtube videos.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        saveYoutubeVideoForAdding ({ commit }, payload) {
            commit('setYoutubeVideoForAdding', payload);
        },
        async addYoutubeVideo ({ dispatch, commit }, payload) {
            const url = 'api/youtube-contents';
            try {
              const response = await axios.post(url, payload);
              commit('setAddedYoutubeVideo', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in adding your youtube video.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        clearYotubeVideoForAdding ({ commit }) {
            commit('removeYoutubeVideoForAdding');
        },
        async linkYoutubeVideoToBoard ({ dispatch }, payload) {
            const url = `api/youtube-contents/${payload.videoId}/digi-boards`;
            try {
              await axios.post(url, payload.digiBoards);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in linking your youtube video to selected devices.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteYoutubeVideo  ({ dispatch }, payload) {
            const url = `api/youtube-contents/${payload.videoId}`;
            try {
                await axios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this youtube video.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async fetchYoutubeVideoDetails ({ dispatch, commit }, payload) {
            const url = `api/youtube-contents/${payload.youtubeVideoId}`;
            try {
                const response = await axios.get(url);
                commit('setYoutubeVideoDetails', response.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the youtube video details.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        saveEditedYoutubeVideoDetails ({ commit }, payload) {
            commit('setEditedYoutubeVideoDetails', payload);
        },
        async updateYoutubeVideo ({ dispatch }, payload) {
            const url = `api/youtube-contents/${payload.youtubeVideoId}`;
            try {
              await axios.put(url, payload.formData);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updting your youtube video.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateYtVideoBoardLink ({ dispatch }, payload) {
            const url = `api/youtube-contents/${payload.youtubeVideoId}/digi-boards`;
            try {
              await axios.put(url, payload.digiBoards);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updting your youtube video.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
};
